import { render, staticRenderFns } from "./TechnicalList.vue?vue&type=template&id=68ff19e4&scoped=true&"
import script from "./TechnicalList.vue?vue&type=script&lang=js&"
export * from "./TechnicalList.vue?vue&type=script&lang=js&"
import style0 from "./TechnicalList.vue?vue&type=style&index=0&id=68ff19e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ff19e4",
  null
  
)

export default component.exports