export default [
    {
        label: '순번',
        field: 'order_number',
        sort: true
    },
    {
        label: '분야',
        field: 'fields',
        sort: true
    },
    {
        label: '제목',
        field: 'title',
        sort: true
    },
    {
        label: '작성일',
        field: 'reg_date',
        sort: true
    },
    {
        label: '작성자',
        field: 'writer',
        sort: true
    },
    {
        label: '조회수',
        field: 'views',
        sort: true,
    },
];